import React from 'react';
import Svg from "../all/Svg";
import withStore from "@/hocs/withStore";
import {Inertia} from "@inertiajs/inertia";
import LinkLoad from "@/Layouts/LinkLoad";
import {getProperty, isMobile} from "@/Helpers/Function";

class SearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.searchInput = React.createRef();
    }

    state = {
        value: '',
        attribute: '',
        cursorHistory: -1,
        cursorSuggestions: 0,
    };

    /**
     * Перемещаемся по списку стрелками на клавиатуре
     * @param e
     * @returns {boolean}
     */
    handleKeyDown = (e) => {
        const {
            value,
            attribute,
            cursorHistory,
            cursorSuggestions
        } = this.state;
        const {stores = {}} = this.props;
        const {searchStore = {}} = stores;

        if (searchStore.searchSuggestions.length !== 0) {
            // up
            if (e.keyCode === 38) {
                if (cursorSuggestions > 0) {
                    this.setState(prevState => ({
                        value: '',
                        attribute: this.state[`searchSuggestions_${prevState.cursorSuggestions - 1}`] ? this.state[`searchSuggestions_${prevState.cursorSuggestions - 1}`].getAttribute('href') : '',
                        cursorSuggestions: prevState.cursorSuggestions - 1
                    }));
                } else {
                    this.setState({
                        value: '',
                        attribute: this.state[`searchSuggestions_${searchStore.searchSuggestions.length - 1}`] ? this.state[`searchSuggestions_${searchStore.searchSuggestions.length - 1}`].getAttribute('href') : '',
                        cursorSuggestions: searchStore.searchSuggestions.length - 1
                    });
                }
                searchStore.searchValueType = 'suggestions';
                // down
            } else if (e.keyCode === 40) {
                if (cursorSuggestions < searchStore.searchSuggestions.length - 1) {
                    this.setState(prevState => ({
                        value: '',
                        attribute: this.state[`searchSuggestions_${prevState.cursorSuggestions + 1}`] ? this.state[`searchSuggestions_${prevState.cursorSuggestions + 1}`].getAttribute('href') : '',
                        cursorSuggestions: prevState.cursorSuggestions + 1
                    }));
                } else {
                    this.setState({
                        value: '',
                        attribute: this.state[`searchSuggestions_0`] ? this.state[`searchSuggestions_0`].getAttribute('href') : '',
                        cursorSuggestions: 0
                    });
                }
                searchStore.searchValueType = 'suggestions';
            }
        } else {
            if (searchStore.searchHistoryData.length !== 0) {
                if (e.keyCode === 38) {
                    if (cursorHistory > 0) {
                        this.setState(prevState => ({
                            attribute: '',
                            value: this.state[`searchHistory_${prevState.cursorHistory - 1}`] ? this.state[`searchHistory_${prevState.cursorHistory - 1}`].textContent : '',
                            cursorHistory: prevState.cursorHistory - 1
                        }));
                        searchStore.searchQuery = searchStore.searchHistoryData[cursorHistory - 1];
                    } else {
                        this.setState({
                            attribute: '',
                            value: this.state[`searchHistory_${searchStore.searchHistoryData.length - 1}`] ? this.state[`searchHistory_${searchStore.searchHistoryData.length - 1}`].textContent : '',
                            cursorHistory: searchStore.searchHistoryData.length - 1
                        });
                        searchStore.searchQuery = searchStore.searchHistoryData[searchStore.searchHistoryData.length - 1];
                    }
                    searchStore.searchValueType = 'history';
                    // down
                } else if (e.keyCode === 40) {
                    if (cursorHistory < searchStore.searchHistoryData.length - 1) {
                        this.setState(prevState => ({
                            attribute: '',
                            value: this.state[`searchHistory_${prevState.cursorHistory + 1}`] ? this.state[`searchHistory_${prevState.cursorHistory + 1}`].textContent : '',
                            cursorHistory: prevState.cursorHistory + 1
                        }));
                        searchStore.searchQuery = searchStore.searchHistoryData[cursorHistory + 1];
                    } else {
                        this.setState({
                            attribute: '',
                            value: this.state[`searchHistory_0`] ? this.state[`searchHistory_0`].textContent : '',
                            cursorHistory: 0
                        });
                        searchStore.searchQuery = searchStore.searchHistoryData[0];
                    }
                    searchStore.searchValueType = 'history';
                }
            }
        }

        // Если нажали Enter
        if (e.keyCode === 13) {
            if (searchStore.searchValueType === 'search' || searchStore.searchValueType === 'history') {
                if (location.pathname !== '/search') {
                    Inertia.visit('/search');
                }
                return searchStore.onClickSearchButton();
            }
            if (searchStore.searchValueType === 'suggestions' && attribute !== '') {
                Inertia.visit(attribute);
                return false;
            }
        }
    };

    /**
     * Обработка истории запросов
     * @param el
     */
    onChangeHistory = (el) => {
        const {stores: {searchStore}} = this.props;
        searchStore.onChangeHistory(el);
        Inertia.visit('/search');
    };

    /**
     * Обработка нажатия кнопки поиска
     */
    onClickSearchButton = () => {
        const {stores: {searchStore}} = this.props;
        return searchStore.onClickSearchButton();
    };

    render() {
        const {stores: {searchStore, modalStore}, user = {}} = this.props;
        const isBarcode = getProperty(user, 'is_barcode', false);
        const {cursorHistory, cursorSuggestions} = this.state;

        let searchSuggestions = [];
        if (searchStore.searchSuggestions.length !== 0) {
            searchSuggestions = searchStore.searchSuggestions.map((el, i) => {
                return <LinkLoad key={i}
                                 href={`${el.url}`}
                                 ref={ref => (this.state[`searchSuggestions_${i}`] = ref)}
                                 className={`searchSuggestions__item${cursorSuggestions === i ? ' focused' : ''}`}>
                    <div className={`searchSuggestions__img`}>
                        {el.img === 'catalog'
                            ? <Svg id={`catalog`} className={`svg__searchSuggestions`} />
                            : <img src={el.img}
                                   alt={el.h1}
                                   loading="lazy" />
                        }
                    </div>
                    <p>{el.h1}</p>
                </LinkLoad>
            });
        }

        let searchHistoryItem = [];
        if (searchStore.searchHistoryData.length !== 0) {
            searchHistoryItem = searchStore.searchHistoryData.map((el, i) => {
                return <div key={i}
                            ref={ref => (this.state[`searchHistory_${i}`] = ref)}
                            onClick={() => this.onChangeHistory(el)}
                            className={`searchHistory__item${cursorHistory === i ? ' focused' : ''}`}>
                    {/*className={`searchHistory__item${cursorHistory === i ? ' focused' : ''}`}>*/}
                    <div className={`searchHistory__svg`}>
                        <Svg id={`searchHistory`} className={`svg__searchHistory`} />
                    </div>
                    <p>{el}</p>
                </div>
            });
        }

        return <>
            <div className={`formSearchWrapper`}>
                <div className={`formSearchWrapper__field`}>
                    <input ref={this.searchInput}
                           name={`search`}
                           type={`text`}
                           placeholder={`Найти в каталоге`}
                           value={searchStore.searchQuery}
                           autoComplete={`off`}
                           autoFocus={searchStore.onHint}
                           onChange={(e) => searchStore.searchChange(e)}
                           onKeyUp={(e) => this.handleKeyDown(e)}
                           onFocus={(e) => searchStore.onSearchFocus(e)}
                           onBlur={(e) => searchStore.onSearchBlur(e)}
                           className={`formSearchWrapper__textInput`}
                    />
                    {searchStore.searchQuery !== '' && (
                        <div className={`dagger-box dagger-box__searchPage`}
                             onClick={() => searchStore.deleteSearchValue(this.searchInput.current)}>
                            <Svg id={`dagger-close`} className={`svg__dagger-close`} />
                        </div>
                    )}
                </div>
                {isMobile() && isBarcode && (
                    <div className={`formSearchWrapper__barcodeReader`} onClick={modalStore.openModalScanner}>
                        <Svg id={`barcodeReader`} className={`svg__barcodeReader`} />
                    </div>
                )}
                {location.pathname === '/search' ?
                    <div className={`formSearchWrapper__button`}
                         onClick={() => this.onClickSearchButton()}
                    >
                        <Svg id={`search`} className={`svg__searchPage`} />
                    </div>
                    :
                    <LinkLoad className={`formSearchWrapper__button`}
                              onClick={() => this.onClickSearchButton()}
                              type={`button`}
                              href={`/search`}>
                        <Svg id={`search`} className={`svg__searchPage`} />
                    </LinkLoad>
                }
            </div>
            {searchStore.onHint && (
                <div className={`searchingPage__hint`}>
                    <div className={`emptyBlock`}></div>
                    <div className={`scrollableBlock`}>
                        {/*{searchStore.searchQuery === '' && searchStore.searchHistoryData.length !== 0 && (*/}
                        {searchHistoryItem.length !== 0 && searchSuggestions.length === 0 && (
                            <div className={`searchHistory`}>
                                <div className={`searchHistory__head`}>
                                    <p>История</p>
                                    <p className={`clear`} onClick={() => searchStore.deleteHistoryAll()}>
                                        Очистить
                                    </p>
                                </div>
                                <div className={`searchHistory__body`}>
                                    {searchHistoryItem}
                                </div>
                            </div>
                        )}
                        {searchSuggestions.length !== 0 && (
                            <div className={`searchSuggestions`}>
                                {searchSuggestions}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    }
}

export default withStore(SearchForm);
